/* eslint-disable no-console */
import constate from 'constate';
import { useCallback, useState } from 'react';

import { ISignInProps, useAuthSession, useRegister, useSignIn, useSignOut } from './apis';

export function useAuthState() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false); // New state
  const { mutateAsync: signInMutation } = useSignIn();
  const { mutateAsync: signOutMutation } = useSignOut();
  const { mutateAsync: registerMutation } = useRegister();

  const { isLoading: sessionIsInitializing } = useAuthSession({
    retry: false,
    onSuccess: () => {
      setIsAuthenticated(true);
    },
    onError: () => {
      // TODO: Clear auth cookies if there was an error authenticating the session
      setIsAuthenticated(false);
    },
  });

  const signIn = useCallback(
    async (props: ISignInProps) => {
      const { email, password } = props;
      try {
        await signInMutation({ email, password });
        setIsAuthenticated(true);
      } catch (error) {
        // Could not sign in for some reason
        setIsAuthenticated(false);
        throw error;
      }
    },
    [signInMutation]
  );

  const register = useCallback(
    async (props: ISignInProps) => {
      // Return type added
      const { email, password } = props;
      try {
        await registerMutation({ email, password });
        setRegistrationSuccess(true); // Set success state
      } catch (error) {
        // Could not register for some reason
        setRegistrationSuccess(false); // Reset success state
        throw error;
      }
    },
    [registerMutation]
  );

  const signOut = useCallback(async () => {
    await signOutMutation();
    setIsAuthenticated(false);
  }, [signOutMutation]);

  return {
    isAuthenticated,
    sessionIsInitializing,
    signIn,
    signOut,
    register,
    registrationSuccess, // Expose success state
  };
}

export const [AuthProvider, useAuthContext] = constate(useAuthState);
