// SignInPage.tsx

import {
  Alert,
  Box,
  Button,
  Card,
  Grid,
  InputLabel,
  Link as MuiLink,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';

import { useAuthContext } from './AuthContext';

export const SignInPage: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showSignInError, setShowSignInError] = useState(false);
  const { signIn, sessionIsInitializing, isAuthenticated } = useAuthContext();
  const [showSuccess, setShowSuccess] = useState(false);

  if (sessionIsInitializing) {
    return null;
  }

  if (isAuthenticated) {
    const queryParams = new URLSearchParams(window.location.search);
    const nextUrlPath = queryParams.get('nextUrl');

    if (nextUrlPath) {
      return <Navigate to={nextUrlPath} replace />;
    }

    return <Navigate to="/" replace />;
  }

  return (
    <Grid container justifyContent="center" sx={{ marginTop: 3 }}>
      <Card
        sx={{
          backgroundColor: '#ffffff',
          padding: 4,
          maxWidth: 500,
          width: '100%',
          boxShadow: 3,
          borderRadius: 3,
        }}
      >
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            setShowSignInError(false);
            setShowSuccess(false);
            try {
              await signIn({
                email: username,
                password,
              });
              setShowSuccess(true);
              setUsername('');
              setPassword('');
            } catch {
              setShowSignInError(true);
            }
          }}
        >
          <Typography
            sx={{ color: '#5a2544', fontWeight: 600, fontSize: '24px' }}
            variant="h3"
            align="center"
          >
            Login
          </Typography>
          <Box sx={{ height: 14 }} />
          {showSuccess && (
            <Box sx={{ mb: 2 }}>
              <Alert severity="success">
                Logged in successfully! Redirecting to your dashboard...
              </Alert>
            </Box>
          )}
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <InputLabel>Email</InputLabel>
              <TextField
                size="small"
                fullWidth
                variant="outlined"
                type="email"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Password</InputLabel>
              <TextField
                size="small"
                fullWidth
                type="password"
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Grid>
            {showSignInError && (
              <Grid item xs={12}>
                <Alert severity="error">
                  Could not sign in. Please check your credentials and try again.
                </Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <Button type="submit" variant="outlined" color="primary" fullWidth>
                Login
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary" align="center">
                Need to register?{' '}
                <MuiLink
                  component={Link}
                  to="/register"
                  sx={{
                    textDecoration: 'none',
                    color: '#5a2544',
                    fontWeight: 500,
                    '&:hover': {
                      textDecoration: 'underline',
                      color: '#4a1e3b',
                    },
                  }}
                >
                  Go here
                </MuiLink>
              </Typography>
            </Grid>
          </Grid>
        </form>
      </Card>
    </Grid>
  );
};
