// EmailConfirmedPage.tsx

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box, Button, Card, Grid, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const EmailConfirmedPage: React.FC = () => {
  const navigate = useNavigate();

  const handleGoToLogin = () => {
    navigate('/sign-in');
  };

  return (
    <Grid container justifyContent="center" alignItems="flex-start" sx={{ minHeight: '100vh' }}>
      <Card
        sx={{
          padding: 4,
          textAlign: 'center',
          maxWidth: 500,
          boxShadow: 3,
          borderRadius: 3,
          backgroundColor: '#ffffff',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <CheckCircleOutlineIcon sx={{ fontSize: 60, color: '#4caf50' }} />
        </Box>
        <Typography variant="h4" sx={{ mb: 2, color: '#333333', fontWeight: 700 }}>
          Email Confirmed!
        </Typography>
        <Typography variant="body1" sx={{ mb: 4, color: '#666666', fontSize: '16px' }}>
          Your email has been successfully confirmed. You can now log in to your account.
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleGoToLogin}
          sx={{
            fontSize: '16px',
            textTransform: 'none',
          }}
        >
          Go to Login
        </Button>
      </Card>
    </Grid>
  );
};
