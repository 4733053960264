import { Box, Button, Card, Grid, InputLabel, TextField, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useProceedingsQuery } from './apis';

enum ProceedingSearchParams {
  PROCEEDING_NUMBERS = 'proceeding_numbers',
  KEYWORDS = 'keywords',
  INDUSTRY = 'industry',
  LATEST_DATE_FROM = 'latest_date_from',
  LATEST_DATE_TO = 'latest_date_to',
  STATE = 'state',
}

interface IQueryStringParams {
  [ProceedingSearchParams.PROCEEDING_NUMBERS]?: string[];
  [ProceedingSearchParams.STATE]: string;
  [ProceedingSearchParams.KEYWORDS]?: string[];
  [ProceedingSearchParams.INDUSTRY]?: string;
  [ProceedingSearchParams.LATEST_DATE_FROM]: string;
  [ProceedingSearchParams.LATEST_DATE_TO]: string;
}

enum ProceedingIndustryParams {
  ELECTRIC = 'Electric',
  COMMUNICATIONS = 'Communications',
  TRANSPORTATION = 'Transportation',
}

const today = new Date();
const TODAY_YYYY_MM_DD = format(today, 'yyyy/MM/dd');

const HIDE_SERVICE_SEARCH_LIST = true;

export const ProceedingSearchPanel = () => {
  const [queryParams, setQueryParams] = useState<IQueryStringParams>({
    // Set default values
    [ProceedingSearchParams.STATE]: 'CA',
    [ProceedingSearchParams.LATEST_DATE_FROM]: '2024/01/01',
    [ProceedingSearchParams.LATEST_DATE_TO]: TODAY_YYYY_MM_DD,
    [ProceedingSearchParams.INDUSTRY]: ProceedingIndustryParams.ELECTRIC,
  });
  const { refetch: searchForProceedings } = useProceedingsQuery();
  const [_, setSearchParams] = useSearchParams();

  const handleInputChange = useCallback(
    (fieldName: ProceedingSearchParams, value: any) => {
      setQueryParams((prevState) => {
        const nextState = { ...prevState };
        // Make sure any values that are empty are deleted from the state object
        if (!value) {
          delete nextState[fieldName];
        } else {
          nextState[fieldName] = value;
        }
        return nextState;
      });
    },
    [setQueryParams]
  );
  const triggerSearch = () => {
    // set the search params in the url, and then refetch data
    const searchParamsToSet: Record<string, any> = {};
    Object.entries(queryParams).forEach(([key, value]) => {
      searchParamsToSet[key] = value;
    });
    setSearchParams(searchParamsToSet);
    // Set timeout helps make sure that the fetch occurs after search params are set
    setTimeout(() => {
      searchForProceedings();
    }, 100);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      triggerSearch();
    }
  };
  return (
    <>
      <Card sx={{ p: 3, mb: 3, backgroundColor: 'transparent' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography sx={{ color: '#5a2544' }}>Search Proceedings</Typography>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Proceeding Number</InputLabel>
            <TextField
              onChange={(e) =>
                handleInputChange(ProceedingSearchParams.PROCEEDING_NUMBERS, e.target.value)
              }
              onKeyDown={handleKeyDown}
              size="small"
              variant="outlined"
              placeholder="R20-05-003, 2005003, 20-05-003, etc"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Keywords</InputLabel>
            <TextField
              onChange={(e) => handleInputChange(ProceedingSearchParams.KEYWORDS, e.target.value)}
              onKeyDown={handleKeyDown}
              size="small"
              variant="outlined"
              placeholder="Solar, Wind, Storage..."
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Industry</InputLabel>
            <TextField
              select
              value={
                queryParams[ProceedingSearchParams.INDUSTRY] || ProceedingIndustryParams.ELECTRIC
              }
              onChange={(e) => handleInputChange(ProceedingSearchParams.INDUSTRY, e.target.value)}
              onKeyDown={handleKeyDown}
              size="small"
              variant="outlined"
              fullWidth
              SelectProps={{
                native: true,
              }}
            >
              {Object.values(ProceedingIndustryParams).map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <InputLabel disabled>State</InputLabel>
            <TextField
              // Only supports CA for now
              value={queryParams.state}
              disabled
              size="small"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Documents Filed From</InputLabel>
            <TextField
              // TODO: use date picker
              value={queryParams[ProceedingSearchParams.LATEST_DATE_FROM]}
              onChange={(e) =>
                handleInputChange(ProceedingSearchParams.LATEST_DATE_FROM, e.target.value)
              }
              size="small"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Documents Filed To</InputLabel>
            <TextField
              value={queryParams[ProceedingSearchParams.LATEST_DATE_TO]}
              onChange={(e) =>
                handleInputChange(ProceedingSearchParams.LATEST_DATE_TO, e.target.value)
              }
              size="small"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
        <Box sx={{ height: 16 }} />
        <Button
          variant="outlined"
          onClick={() => {
            // set the search params in the url, and then refetch data
            const searchParamsToSet: Record<string, any> = {};
            Object.entries(queryParams).forEach(([key, value]) => {
              searchParamsToSet[key] = value;
            });
            setSearchParams(searchParamsToSet);
            // Set timeout helps make sure that the fetch occurs after search params are set
            setTimeout(() => {
              searchForProceedings();
            }, 100);
          }}
        >
          Search
        </Button>
      </Card>
      {!HIDE_SERVICE_SEARCH_LIST && (
        <Card sx={{ p: 3, mb: 3, backgroundColor: 'transparent' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography sx={{ color: '#5a2544' }}>Search Service Lists</Typography>
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Proceeding Number</InputLabel>
              <TextField
                onChange={(e) =>
                  handleInputChange(ProceedingSearchParams.PROCEEDING_NUMBERS, e.target.value)
                }
                size="small"
                variant="outlined"
                placeholder="R20-05-003, 2005003, 20-05-003, etc"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>First Name</InputLabel>
              <TextField
                onChange={(e) => handleInputChange(ProceedingSearchParams.KEYWORDS, e.target.value)}
                size="small"
                variant="outlined"
                placeholder="Jane"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Last Name</InputLabel>
              <TextField
                onChange={(e) => handleInputChange(ProceedingSearchParams.KEYWORDS, e.target.value)}
                size="small"
                variant="outlined"
                placeholder="Doe"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Email</InputLabel>
              <TextField
                onChange={(e) => handleInputChange(ProceedingSearchParams.KEYWORDS, e.target.value)}
                size="small"
                variant="outlined"
                placeholder="jane@solarinc.com"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Company</InputLabel>
              <TextField
                onChange={(e) => handleInputChange(ProceedingSearchParams.KEYWORDS, e.target.value)}
                size="small"
                variant="outlined"
                placeholder="Solar Inc."
                fullWidth
              />
            </Grid>
          </Grid>
          <Box sx={{ height: 16 }} />
          <Button
            variant="outlined"
            onClick={() => {
              triggerSearch();
            }}
          >
            Search
          </Button>
        </Card>
      )}
    </>
  );
};
