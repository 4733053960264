// RegisterPage.tsx

import {
  Alert,
  Box,
  Button,
  Card,
  Grid,
  InputLabel,
  Link as MuiLink,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { useAuthContext } from './AuthContext';

export const RegisterPage: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showSignInError, setShowSignInError] = useState(false);
  const { register, registrationSuccess } = useAuthContext();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setShowSignInError(false);
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    if (!passwordRegex.test(password)) {
      setPasswordError(
        'Password must be at least 8 characters long and include at least one lowercase letter, one uppercase letter, and one number.'
      );
      return;
    }
    setPasswordError('');

    try {
      await register({
        email: username,
        password,
      });
    } catch {
      setShowSignInError(true);
    }
  };

  return (
    <Grid container justifyContent="center" sx={{ marginTop: 3 }}>
      <Card sx={{ backgroundColor: 'transparent', padding: 4, maxWidth: 500, width: '100%' }}>
        <form onSubmit={handleSubmit}>
          <Typography
            sx={{ color: '#5a2544', fontWeight: 600, fontSize: '24px' }}
            variant="h3"
            align="center"
          >
            Register
          </Typography>
          <Box sx={{ height: 14 }} />
          {registrationSuccess && (
            <Box sx={{ mb: 2 }}>
              <Alert severity="success">
                Registration successful! Please check your email to confirm your address.
              </Alert>
            </Box>
          )}
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <InputLabel>Email</InputLabel>
              <TextField
                size="small"
                fullWidth
                variant="outlined"
                type="email"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Password</InputLabel>
              <TextField
                size="small"
                fullWidth
                type="password"
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={!!passwordError}
                helperText={passwordError}
                required
              />
            </Grid>
            {showSignInError && (
              <Grid item xs={12}>
                <Alert severity="error">
                  Could not register. You may not be authorized to register at this time.
                </Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <Button type="submit" variant="outlined" color="primary" fullWidth>
                Register
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary" align="center">
                Already registered?{' '}
                <MuiLink
                  component={Link}
                  to="/sign-in"
                  sx={{ textDecoration: 'none', color: '#5a2544', fontWeight: 500 }}
                >
                  Log in here
                </MuiLink>
              </Typography>
            </Grid>
          </Grid>
        </form>
      </Card>
    </Grid>
  );
};
